import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useActions } from '../../hooks/useActions';
import { styles, theme } from '../../style';
import { Button } from '../common/Button2';
import { Link } from '../common/Link';
import { Paragraph } from '../common/Paragraph';
import { Text } from '../common/Text';
import { View } from '../common/View';

export function WelcomeScreen() {
  const { t } = useTranslation();
  const { createBudget, pushModal } = useActions();

  return (
    <View
      style={{
        gap: 10,
        maxWidth: 500,
        fontSize: 15,
        maxHeight: '100vh',
        marginBlock: 20,
      }}
    >
      <Text style={styles.veryLargeText}>{t('Let’s get started!')}</Text>
      <View style={{ overflowY: 'auto' }}>
        <Paragraph>
          <Trans>
            Your Numbers is a personal finance tool that focuses on beautiful
            design and a slick user experience.{' '}
            <strong>Editing your data should be as fast as possible.</strong>
          </Trans>
        </Paragraph>
        <Paragraph>
          Your Numbers implements the budgeting system. Consider taking a look
          at{' '}
          <Link
            variant="external"
            to="https://docs.your-numbers.app"
            linkColor="purple"
          >
            our help
          </Link>{' '}
          to help you get your bearings, and check out the rest of the
          documentation while you’re there to learn more about advanced topics.
        </Paragraph>
        <Paragraph style={{ color: theme.pageTextLight }}>
          Get started by importing an existing budget file from Actual/Your
          Numbers or another budgeting app, create a demo budget file, or start
          fresh with an empty budget. You can always create or import another
          budget later.
        </Paragraph>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexShrink: 0,
        }}
      >
        <Button onPress={() => pushModal('import')}>
          {t('Import my budget')}
        </Button>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 10,
          }}
        >
          <Button onPress={() => createBudget({ testMode: true })}>
            {t('View demo')}
          </Button>
          <Button variant="primary" onPress={() => createBudget()}>
            {t('Start fresh')}
          </Button>
        </View>
      </View>
    </View>
  );
}
